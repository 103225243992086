import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Design is...",
  "date": "2014-07-30T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1100px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.18181818181818%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAb8VmJJiEf/EABsQAAIDAAMAAAAAAAAAAAAAAAECAAMEEzM0/9oACAEBAAEFAhaGaw8SDWgg7Nnmn//EABYRAAMAAAAAAAAAAAAAAAAAABARIf/aAAgBAwEBPwFwf//EABgRAAIDAAAAAAAAAAAAAAAAAAIDEBES/9oACAECAQE/AWLHVx//xAAdEAACAgEFAAAAAAAAAAAAAAAAAQIRgRASIUJx/9oACAEBAAY/Aqt8m5yb8OzI4MrT/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARIWEQ8P/aAAgBAQABPyGi7c6YS7MOcQak7IQhSfCEMFeX/9oADAMBAAIAAwAAABBoH//EABcRAQADAAAAAAAAAAAAAAAAAAEQIUH/2gAIAQMBAT8QEU2P/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERMf/aAAgBAgEBPxCSiwp//8QAHBABAAICAwEAAAAAAAAAAAAAAQARITFBUXGB/9oACAEBAAE/EHGlAGT7xLESDQVPOfI6V2uQ8wwhAF0IN1dAruLP/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "design is",
          "title": "design is",
          "src": "/static/8db52124f8cf816d7665aca7207f67df/ddced/design-is.jpg",
          "srcSet": ["/static/8db52124f8cf816d7665aca7207f67df/35f54/design-is.jpg 275w", "/static/8db52124f8cf816d7665aca7207f67df/d7854/design-is.jpg 550w", "/static/8db52124f8cf816d7665aca7207f67df/ddced/design-is.jpg 1100w", "/static/8db52124f8cf816d7665aca7207f67df/670dc/design-is.jpg 1650w", "/static/8db52124f8cf816d7665aca7207f67df/a40a7/design-is.jpg 2200w", "/static/8db52124f8cf816d7665aca7207f67df/df51d/design-is.jpg 2400w"],
          "sizes": "(max-width: 1100px) 100vw, 1100px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "http://shopify.com"
      }}>{`We’ve`}</a>{` recently teamed up with `}<a parentName="p" {...{
        "href": "http://dribbble.com"
      }}>{`Dribbble`}</a>{` for an `}<a parentName="p" {...{
        "href": "https://dribbble.com/shots/1650934-Design-is-Official-Shopify-Dribbble-Playoff/rebounds"
      }}>{`official playoff`}</a>{` centered around the theme of “What Does Design Mean to You?” To choose the main shot we had an internal competition to determine who came up with the most interesting shot. I didn’t win unfortunately, but Paul Pritchard wrote `}<a parentName="p" {...{
        "href": "http://shopifypartnerblog.myshopify.com/blogs/blog/15409119-what-does-design-mean-to-you-enter-shopify-dribbble-s-design-contest"
      }}>{`an excellent article`}</a>{` about the design process behind his winning shot. Well worth a read.`}</p>
    <p>{`There’s still time to `}<a parentName="p" {...{
        "href": "https://dribbble.com/shots/1650934-Design-is-Official-Shopify-Dribbble-Playoff/rebounds"
      }}>{`enter the playoff`}</a>{` (you could win really cool prizes). The contest ends Tuesday, August 5th. Can’t wait to see what design means to you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      